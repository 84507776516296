import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/QuizResult/PageContents"

const QuizResultPage = ({ location, data }) => {
  const path = location.pathname
  const { page } = data
  return (
    <Layout>
      <Seo
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
      />
      <PageContents blocks={page.blocks} />
    </Layout>
  )
}

export default QuizResultPage

export const pageQuery = graphql`
  query QuizResultQuery($slug: String!) {
    page: contentfulQuizResult(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulIntroBlock {
          id
          header
          subHeader {
            raw
          }
        }
        ... on ContentfulArticleCarouselBlock {
          id
          entryTitle
          header
          ctaLabel
          ctaLink
          subHeader {
            raw
          }
          slides {
            __typename
            title
            slug
            thumbnail {
              gatsbyImageData(width: 636, aspectRatio: 1)
            }
            category {
              slug
              categoryColor
              title
            }
          }
        }
        ...expertCarouselFragment
        ... on ContentfulTextImageBlock {
          id
          image {
            title
            gatsbyImageData(width: 1356)
          }
          content {
            raw
          }
          imagePosition
        }
        ... on ContentfulProductHighlightBlock {
          id
          ctaLabel
          introContent {
            raw
          }
          product {
            productSlug
          }
        }
      }
    }
  }
`
