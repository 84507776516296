import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ButtonGrey from "../Core/Buttons/ButtonGrey"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import { getCategoryLink } from "../../services/blog/link"
import { Link } from "gatsby"
import { isBrowser } from "../../services/browser"
import Arrow from "../../resources/img/ui/dark/chevron-down-dark.svg"


const Background = styled.div`
  padding: 83px 0 113px;
  background: ${colors.peach};
  position: relative;
`

const IntroBlock = styled.div`
  text-align: center;
  max-width: 671px;
  margin: auto;
`

const IntroContent = styled.div`
  font-size: 18px;
  line-height: 28px;
  margin-top: 13px;
`

const Title = styled.h2`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 40px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 72px;
    line-height: 72px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 43px;
  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    a {
      min-width: 249px;
    }
  }
`

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    outline: none !important;
    height: inherit !important;
    display: flex;
  }
`

const Slide = styled.div`
  padding: 136px 11px 0;
  display: flex;
  height: 100%;

  @media (min-width: ${breakpoints.md}) {
    padding: 136px 24px 0;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 136px 48px 0;
  }
`

const TipCard = styled.div`
  background: ${colors.ivory};
  border: 1px solid ${colors.grey};
  border-radius: 15px;
  padding: 81px 23px 29px;
  position: relative;
  width: calc(100vw - 100px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}) {
    max-width: 318px;
  }
`

const ExpertImage = styled(GatsbyImage)`
  width: 109px;
  height: 109px;
  border-radius: 50%;
  border: 1px solid ${colors.grey};
  position: absolute !important;
  top: -54px;
  left: 50%;
  transform: translateX(-50%);
`

const Category = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  text-align: center;
  a {
    color: inherit;
    text-decoration: none;
  }
  span {
    border-bottom: 2px solid ${(props) => props.color};
  }
`

const TipOn = styled.div`
  ${fonts.canelaThinItalic};
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 6px;
`

const Tip = styled.div`
  margin: 33px auto 22px;
  text-align: center;
  p {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 22px;
    :last-of-type {
      margin-bottom: 0;
    }

    b {
      ${fonts.labGrotesqueBold};
    }

    i {
      font-style: italic;
    }
  }

  ul {
    margin: 22px auto;
    li {
      list-style: disc;
      width: max-content;
      margin: auto;
    }
  }

  a {
    color: ${colors.grey};
  }
`

const ExpertName = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  padding: 0 10px;
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 50px;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
`

const SlideArrow = styled.div`
  cursor: pointer;
  transform: ${(props) => (props.prev ? "rotate(90deg)" : "rotate(-90deg)")};
  
  img {
    height: 10px;
    width: auto;
    display: block;
    margin: ${(props) => (props.prev ? "0 15px 0 0" : "0 0 0 15px")};
  }
`

const ExpertCarousel = (props) => {
  const { title, intro, cards, ctaLink, ctaLabel } = props
  let sliderRef = useRef(null)

  const [width, setWidth] = useState(0)

  const getWindowWidth = () => {
    if (isBrowser()) {
      setWidth(window.innerWidth)
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      getWindowWidth()
      window.addEventListener("resize", getWindowWidth)
    }
    return () => {
      window.removeEventListener("resize", getWindowWidth)
    }
  })

  const settings = {
    infinite: true,
    initialSlide: 0,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
  }

  const next = () => {
    sliderRef.slickNext()
  }

  const previous = () => {
    sliderRef.slickPrev()
  }

  return (
    <Background>
      <IntroBlock>
        <Title>{title}</Title>
        {intro && (
          <IntroContent>{renderRichText(intro)}</IntroContent>
        )}
        {ctaLink && ctaLabel && (
          <ButtonContainer>
            <ButtonGrey to={ctaLink}>{ctaLabel}</ButtonGrey>
          </ButtonContainer>
        )}
      </IntroBlock>
      {width >= 768 && cards.length < 3 ? (
        <Grid length={cards.length}>
          {cards.map((card, index) => {
            const { expert, category, tip } = card
            return (
              <Slide key={index}>
                <TipCard>
                  <ExpertImage image={expert.image.gatsbyImageData} alt={expert.name} />
                  <TipOn>Tip on</TipOn>
                  <Category index={index} color={category.categoryColor}>
                    <Link to={getCategoryLink(category.slug)}>
                      <span>{category.title}</span>
                    </Link>
                  </Category>
                  <Tip>{renderRichText(tip)}</Tip>
                  <ExpertName>{expert.name}</ExpertName>
                </TipCard>
              </Slide>
            )
          })}
        </Grid>
      ) : (
        <>
        <StyledSlider {...settings} ref={(ref) => (sliderRef = ref)}>
          {cards.map((card, index) => {
            const { expert, category, tip } = card
            return (
              <Slide key={index}>
                <TipCard>
                  <div>
                    <ExpertImage image={expert.image.gatsbyImageData} alt={expert.name} />
                    <TipOn>Tip on</TipOn>
                    <Category index={index} color={category.categoryColor}>
                      <Link to={getCategoryLink(category.slug)}>
                        <span>{category.title}</span>
                      </Link>
                    </Category>
                    <Tip>{renderRichText(tip)}</Tip>
                  </div>
                  <ExpertName>{expert.name}</ExpertName>
                </TipCard>
              </Slide>
            )
          })}
        </StyledSlider>
          <ButtonsContainer>
            <SlideArrow onClick={previous} prev>
              <img src={Arrow} alt="previous"></img>
            </SlideArrow>
            <SlideArrow onClick={next}>
              <img src={Arrow} alt="next"></img>
            </SlideArrow>
          </ButtonsContainer>
        </>
      )}
    </Background>
  )
}

export default ExpertCarousel
