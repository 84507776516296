import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import IntroBlock from "./IntroBlock"
import ArticlesBlock from "./ArticlesBlock"
import TextImageBlock from "./TextImageBlock"
import ProductHighlight from "../Core/ProductHighlight"
import Half from "../../resources/img/core/background/half.png"
import ExpertCarousel from "../Core/Experts"
import ExpertTipCarousel from "./ExpertTipCarousel"

const PageContent = styled.div`
  position: relative;
`

const Circle = styled.div`
  background-image: url(${Half});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  height: 50vw;
  transform: scaleY(-1);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  @media (min-width: ${breakpoints.xxl}) {
    background-size: 100% 800px;
  }
`

const ContentContainer = styled.div`
  padding-top: 112px;
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 167px;
  }
`

const PageContents = ({ blocks }) => {
  let pageContents = []

  blocks.forEach((block, index) => {
    const type = block.__typename

    if (type === "ContentfulIntroBlock") {
      pageContents.push(<IntroBlock {...block} key={index} />)
    }
    if (type === "ContentfulArticleCarouselBlock") {
      pageContents.push(<ArticlesBlock {...block} key={index} />)
    }
    if (type === "ContentfulTextImageBlock") {
      pageContents.push(<TextImageBlock {...block} key={index} />)
    }
    if (type === "ContentfulProductHighlightBlock") {
      pageContents.push(<ProductHighlight {...block} key={index} />)
    }
    if (type === "ContentfulExpertCarousel") {
      if (block.cards.every((card) => card.__typename === "ContentfulExpert")) {
        pageContents.push(<ExpertCarousel {...block} key={index} />)
      }
      if (block.cards.every((card) => card.__typename === "ContentfulExpertTip")) {
        pageContents.push(<ExpertTipCarousel {...block} key={index} />)
      }
    }
  })

  return (
    <PageContent>
      <Circle></Circle>
      <ContentContainer>{pageContents}</ContentContainer>
    </PageContent>
  )
}

export default PageContents
