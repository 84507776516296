import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import BlockContainer from "../Core/Container/BlockContainer"

const IntroContainer = styled.div`
    padding: 0 29px;
    @media (min-width: ${breakpoints.md}) {
        padding: 0 42px;
    }
`

const Title = styled.h2`
    text-align: center;
    ${fonts.canelaThin}
    font-size: 72px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 32px;
    @media (min-width: ${breakpoints.md}) {
        padding: 0 24px;
    }
`
const IntroContent = styled.div`
    margin-bottom: 73px;
    p {
        ${fonts.labGrotesqueLight}
        font-size: 18px;
        font-weight: 400;
        line-height: 1.6;
        text-align: center;
        max-width: 500px;
        margin: 0 auto 30px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
`

const IntroBlock = (props) => {

    const { header, subHeader } = props

    return (
        <BlockContainer>
            <IntroContainer>
                <Title>{header}</Title>
                {subHeader && <IntroContent>{renderRichText(subHeader)}</IntroContent>}
            </IntroContainer>
        </BlockContainer>
    )
}

export default IntroBlock
