import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import BlockContainer from "../Core/Container/BlockContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Col, Row } from "styled-bootstrap-grid"
import { ButtonStyleOrangeDark } from "../Core/Buttons/Styles"
import { lambdaCall } from "../../services/lambdaCall"

const TextImageBlockContainer = styled.div`
  margin: 38px 28px 91px;
  @media (min-width: ${breakpoints.lg}) {
    margin: 43px 42px 91px;
  }
`

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 678px;
    min-height: 100%;
    ${(props) => (props.left ? "margin-right: auto" : "margin-left: auto")};
  }
`

const Content = styled.div`
  text-align: center;
  h2 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 28px;
    :last-of-type {
      margin-bottom: 0;
    }
  }


  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
    h2 {
      margin-bottom: 25px;
    }
  }
`
const StyledInput = styled.input`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 1.8;
  font-weight: 300;
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  border-bottom: 1px solid ${colors.grey};
  margin-top: 40px;
  padding-bottom: 13px;

  ::-webkit-input-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  ::-moz-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  :-ms-input-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  :-moz-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 64px;
    max-width: 520px;
  }

`

const StyledCol = styled(Col)`
  margin-top: 63px;
  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
    margin-top: 0;
    ${(props) => (props.right ? "padding-left: 99px" : "padding-right: 99px")};
    ${(props) => (props.right ? "padding-right: 58px" : "padding-left: 66px")};
  }
`
const ButtonContainer = styled.div`
  margin: 13px 0;
  @media (min-width: ${breakpoints.md}) {
    max-width: 520px;
  }
`

const Submit = styled.button`
  ${ButtonStyleOrangeDark};
  width: 100%;
`

const Information = styled.div`
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    width: 100%;
`

const TextImageBlock = (props) => {
  const { imagePosition, image, content } = props
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const signUp = (e) => {
    e.preventDefault();

    const body = JSON.stringify({
      email: email,
    })
    lambdaCall({
      url: "quizSignup",
      method: "POST",
      body: body,
    })
      .then((response) => {
        return Promise.all([response.status, response.text()])
      })
      .then(([status]) => {
        if (status === 200) {
          setSubmitted(true)

          setTimeout(() => {
            setSubmitted(false)
          }, 3000);
        } else {
          console.log("error")
        }
      })
  }
  return (
    <BlockContainer>
      <TextImageBlockContainer>
        <Row>
          <Col lg={6}>
            <StyledImage
              image={image.gatsbyImageData}
              alt={image.title}
              left={imagePosition ? 1 : 0}
            />
          </Col>
          <StyledCol
            lg={6}
            lgOrder={imagePosition ? "last" : "first"}
            right={imagePosition ? 1 : 0}
            lgAlignSelf="center"
          >
            <Content>{renderRichText(content)}</Content>
            <StyledInput
                    type="email"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    name={"email"}
                    autoComplete={"off"}
                />
                <ButtonContainer>
                    <Submit onClick={(e) => signUp(e)}>{submitted ? "Thank you!" : "Submit"} </Submit>
                </ButtonContainer>
                <Information>By signing up you agree to receive Hummingway's email newsletter and special promotional offers.</Information>
          </StyledCol>
        </Row>
      </TextImageBlockContainer>
    </BlockContainer>
  )
}

export default TextImageBlock
