import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ArticleCard from "../Blog/ArticleCard"
import BlockContainer from "../Core/Container/BlockContainer"
import ButtonOrangeDark from "../Core/Buttons/ButtonOrangeDark"

const ArticlesContainer = styled.div`
    padding: 0 29px;
    @media (min-width: ${breakpoints.md}) {
        padding: 0 42px;
    }
`

const Title = styled.div`
    p {
        ${fonts.canelaThin};
        font-size: 40px;
        font-weight: 200;
        line-height: 1;
        text-align: center;
    }
    b {
        ${fonts.labGrotesqueLight};
        font-size: inherit;
        font-weight: 400;
        line-height: inherit;
        text-transform: uppercase;
    }

`
const Articles = styled.div`
    max-width: 732px;
    margin: 90px auto 0;
    display: grid;
    gap: 86px;
    grid-template-columns: 1fr;

    @media (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr; 
        gap: 96px;
        margin: 110px auto 0;
    }
`

const ArticleCardContainer = styled.div`
  width: 100%;
`

const ButtonContainer = styled.div`
    margin: 45px 0 93px;
    a {
        padding: 16.5px 18px;
    }
    @media (min-width: ${breakpoints.md}) {
        margin: 106px auto 93px;
        max-width: 249px;
    }
   
`

const ArticlesBlock = (props) => {
    const {subHeader, slides, ctaLabel, ctaLink} = props
    return (
        <BlockContainer>
            <ArticlesContainer>
                <Title>{renderRichText(subHeader)}</Title>
                <Articles>
                    {slides.map((slide, index) => (
                        <ArticleCardContainer key={index}>
                        <ArticleCard {...slide} />
                        </ArticleCardContainer>
                    ))}
                </Articles>
                {ctaLabel && ctaLink && (
                        <ButtonContainer>
                            <ButtonOrangeDark to={ctaLink}>
                            {ctaLabel}
                            </ButtonOrangeDark>
                        </ButtonContainer>
                        )}
            </ArticlesContainer>
        </BlockContainer>
    )
}

export default ArticlesBlock
