import React from "react"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { getArticleLink, getCategoryLink } from "../../services/blog/link"
import ArrowButton from "../Core/Buttons/ArrowButton"
import { Link } from "gatsby"

const CardContainer = styled.div`
  text-align: center;
`

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;
  max-width: ${(props) => (props.search ? "100%" : "calc(100vw - 150px)")};
  margin: auto;
  margin-bottom: 43px;

  @media (min-width: ${breakpoints.md}) {
    max-width: unset;
    margin-bottom: 46px;
  }
`

const Category = styled(Link)`
  ${fonts.labGrotesqueBold};
  line-height: normal;
  font-size: 19px;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.grey};
  span {
    border-bottom: 2px solid ${(props) => props.color};
  }
`

const Title = styled.div`
  ${fonts.canelaThin};
  font-size: 24px;
  line-height: 120%;
  margin-top: 26px;
  margin-bottom: 15px;
`

const ArticleCard = (props) => {
  const { title, slug, thumbnail, category, search } = props
  return (
    <CardContainer>
      <div>
        <StyledImage
          image={thumbnail.gatsbyImageData}
          alt={title}
          search={search ? 1 : 0}
        />
      </div>
      <Category
        to={getCategoryLink(category.slug)}
        color={category.categoryColor}
      >
        <span>{category.title}</span>
      </Category>
      <Title>{title}</Title>
      <ArrowButton to={getArticleLink(slug)}>Read More</ArrowButton>
    </CardContainer>
  )
}

export default ArticleCard
